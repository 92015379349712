.root {
  position: fixed;
  width: 100%;
  border-top: 2px solid #e7e6e4;
  background-color: white;
  padding: 12px;
  z-index: 5;
}

.root.showAboveBottomNavbar {
  bottom: 80px;
}

.root:not(.showAboveBottomNavbar) {
  bottom: 0;
}
