.desktopModalPaper {
  width: 564px !important;
  border-radius: 20px !important;
}

.modalFooter {
  padding: 16px 32px 53px 32px;
}

@media (max-width: 767px) {
  .modalPaper {
    height: 92vh !important;
    overflow-y: hidden !important;
  }
  .modalFooter {
    padding: 16px 29px 16px 29px;
  }
}
