.iconButton {
  padding: 0 !important;
}
.icon {
  color: #493ab1;
}

.wrap {
  padding: 8px 0 36px 0;
}

.action {
  display: flex !important;
  padding: 16px 16px 15px 16px !important;
  gap: 11px;
  width: 100%;
  justify-content: flex-start !important;
}

.action:hover {
  background-color: #e7e6e4;
}

.action + .action {
  border-top: 1px solid #e7e6e4;
}

.action.disabled {
  opacity: 0.5;
}
