.title {
  font-weight: 600;
}

.container {
  z-index: 5;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid #dee2e6;
}

@media screen and (max-width: 780px) {
  .container {
    position: sticky;
    top: 64px;
    background-color: white;
  }

  .title {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 781px) {
  .title {
    font-size: 18px;
  }
}

.backIcon {
  margin-right: 16px;
}
