.warningIcon {
  font-size: 24px !important;
  fill: black !important;
}

.warningContainer {
  background-color: rgba(255, 170, 0, 1);
  color: black;
}

.expiredContainer {
  background-color: rgba(191, 38, 0, 1);
  color: white;
}

.errorIcon {
  font-size: 24px !important;
  fill: rgba(191, 38, 0, 1) !important;
}

.expiredIcon {
  fill: white !important;
}

.cta {
  font-weight: 600;
  text-decoration: underline;
}

@media screen and (max-width: 780px) {
  .waringText {
    font-size: 12px;
  }

  .warningIcon {
    font-size: 20px !important;
  }
}
