.row {
  padding: 10px 0px 10px 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  border-top: 1px solid #e7e6e4 !important;
}

.row_title {
  flex-shrink: 0;
  line-height: 20px;
  font-weight: 500;
}

.row_value {
  line-height: 19px;
  font-weight: 400;
  word-break: break-word;
  text-align: right;
}

@media (max-width: 768px) {
  .row {
    font-size: 14px;
  }
}
