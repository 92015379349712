.infoRoot {
  display: flex;
  gap: 8px;
  padding: 10px 8px;
  background: #fff4ca;
  margin-top: 12px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
}

.infoRoot svg {
  font-size: 18px;
}
