.timepicker {
  border: none !important;
  height: 32px !important;
  width: 100% !important;
}

.noBorder {
  border: none !important;
}

.mobileInput {
  width: 100% !important;
}
