.error {
  border: 1.5px solid #bf2600 !important;
}

.errorText {
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 6px;
  font-size: 11px;
  color: #bf2600;
  line-height: normal;
}

.errorText svg {
  font-size: 21px;
}
