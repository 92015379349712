.label {
  margin: 0 !important;
  gap: 12px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #272522;
}

.group {
  gap: 8px;
}

.helperText {
  margin-top: 8.75px !important;
  font-size: 12px;
  color: #272522 !important;
}

.error {
  color: #bf2600 !important;
}

.disabled {
  pointer-events: none;
}
