.container {
  display: flex;
  align-items: center;
  margin: 4px 0 4px 0;
}

.keyText {
  white-space: nowrap;
}

.valueText {
  word-break: break-all;
}
@media screen and (max-width: 780px) {
  .container {
    flex-direction: column;
    align-items: flex-end;
  }
}
